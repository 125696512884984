import { render, staticRenderFns } from "./ZTS-HR22.vue?vue&type=template&id=5952cbc0&scoped=true&"
import script from "./ZTS-HR22.vue?vue&type=script&lang=js&"
export * from "./ZTS-HR22.vue?vue&type=script&lang=js&"
import style0 from "./ZTS-HR22.vue?vue&type=style&index=0&id=5952cbc0&scoped=true&lang=less&"
import style1 from "./ZTS-HR22.vue?vue&type=style&index=1&id=5952cbc0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5952cbc0",
  null
  
)

export default component.exports